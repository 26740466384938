import request from "utils/api";
import { notification } from "antd";
import moment from "moment";
import { ROLES_LIST, ROLES_LIST_PERMISSION } from "utils/constants";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { parsePhoneNumber } from "react-phone-number-input";
import i18n from "i18n";
import "moment/locale/vi"; // without this line it didn't work

const Helper = {
  getAuthToken: () => localStorage.getItem("op_token"),
  storeAuthToken: (token) => localStorage.setItem("op_token", token),
  removeAuthToken: () => localStorage.removeItem("op_token"),

  uploadFile: async (file) => {
    if (file) {
      const payload = new FormData();
      // payload.append('type', file.type);
      payload.append("file", file.file);

      try {
        return await request("/api/upload/upload-image-not-thumb", payload);
      } catch (e) {
        return null;
      }
    }
    return null;
  },
  toastr: (description, message, type = "success", placement) => {
    const content = {
      message,
      description,
      placement,
    };
    if (type === "success") {
      notification.success(content);
    } else if (type === "error") {
      notification.error(content);
    }
  },
  arrayToString: (array = []) => array.map((item) => item).join(", "),

  checkEmptyHtmlRaw: (content) => {
    if (typeof content !== "string") {
      return false;
    }
    return content.replace(/<p>|&nbsp;|<\/p>/gi, "").trim() === "";
  },

  formatParams: (searchObject, { arrayParams = [] }) => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    Object.keys(searchObject).forEach((key) => {
      if (arrayParams.includes(key) && typeof searchObject[key] === "string") {
        searchObject[key] = [searchObject[key]];
      }
    });
    const { orderBy = "createdAt" } = searchObject;
    const asc = searchObject.order === "descend" ? -1 : 1;
    if (orderBy === "createdAt") {
      searchObject.sort = asc;
      delete searchObject.sortTitle;
      delete searchObject.sortEmail;
      delete searchObject.sortAccuracy;
      delete searchObject.sortName;
      delete searchObject.sortState;
      delete searchObject.sortGuarantee;
      delete searchObject.sortAmount;
      delete searchObject.sortPrice;
      delete searchObject.sortPromotionPrice;
    } else {
      searchObject[`sort${capitalizeFirstLetter(orderBy)}`] = asc;
      Object.keys(searchObject).forEach((i) => {
        if (
          i.startsWith("sort") &&
          i !== `sort${capitalizeFirstLetter(orderBy)}`
        ) {
          delete searchObject[i];
        }
      });
    }
    return searchObject;
  },
  getTimeStamp: (date) => {
    const timePost = moment(date);
    const currentTime = moment();
    timePost.locale("vi");
    if (!date || !timePost.isValid()) {
      return "";
    }
    if (currentTime.diff(timePost, "s") < 60) {
      return "Vừa nãy";
    }

    if (currentTime.diff(timePost, "m") < 60) {
      return `${currentTime.diff(timePost, "m")} phút trước`;
    }

    if (currentTime.diff(timePost, "h") < 24) {
      return `${currentTime.diff(timePost, "h")} giờ trước`;
    }

    if (currentTime.diff(timePost, "d") <= 7) {
      return `${currentTime.diff(timePost, "d")} ngày trước`;
    }

    return timePost.format("DD/MM/YYYY");
  },
  sortUserPermission: (userPermission = []) => {
    if (!Array.isArray(userPermission)) {
      return [];
    }
    const defaultOrder = Object.keys(ROLES_LIST_PERMISSION).map(
      (key) => ROLES_LIST_PERMISSION[key].value
    );

    return defaultOrder.filter((item) => {
      const permission = userPermission.find((per) => per === item);
      return !!permission;
    });
  },
  convertObjectToOptions: (object, emptyOption = false, emptyLabel = "") => {
    const options = Object.keys(object).map((key) => ({
      value: key,
      label: object[key],
    }));
    if (emptyOption) {
      options.unshift({
        value: "",
        label: emptyLabel,
      });
    }
    return options;
  },
  formatPrice: (number) => {
    if (number < 1e3) return number;
    if (number >= 1e3 && number < 1e6) return `${+(number / 1e3).toFixed(1)}K`;
    if (number >= 1e6 && number < 1e9) return `${+(number / 1e6).toFixed(1)}M`;
    if (number >= 1e9 && number < 1e12) return `${+(number / 1e9).toFixed(1)}B`;
    if (number >= 1e12) return `${+(number / 1e12).toFixed(1)}T`;
    return "";
  },

  convertMinutesToMoment: (num) => {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    return moment(
      `${hours.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}:
    ${minutes.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`,
      "HH:mm"
    );
  },
  calculateRemainingTime(givenTime) {
    // Parse the given time string to create a Date object
    const givenDateTime = new Date(givenTime);
    // Get the current time
    const currentDateTime = new Date();
    // Calculate the time difference in milliseconds
    const timeDifference = givenDateTime.getTime() - currentDateTime.getTime();
    // Convert milliseconds to hours and minutes
    const remainingHours = Math.floor(timeDifference / (1000 * 60 * 60));
    const remainingMinutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    if (remainingHours >= 0 && remainingMinutes >= 0) {
      // Format the result
      const remainingTime = `${remainingHours} giờ ${remainingMinutes} phút`;
      return remainingTime;
    }
    return null;
  },
  formatTimeInDate: (number) => {
    const hourValue = Math.floor(number / 60);
    const hourDisplay = hourValue > 12 ? hourValue - 12 : hourValue;
    const minuteValue = number % 60;
    const minuteDisplay = minuteValue >= 10 ? minuteValue : `0${minuteValue}`;
    const isAnteMeridiem = hourValue < 12 ? "AM" : "PM";
    return `${hourDisplay}:${minuteDisplay} ${isAnteMeridiem}`;
  },
  getLatLng: async (address, initLatLng = {}) => {
    try {
      const result = await geocodeByAddress(address);
      const latLng = await getLatLng(result[0]);
      return {
        lng: latLng.lng,
        lat: latLng.lat,
      };
    } catch {
      return {
        lng: initLatLng.lng || 106.6,
        lat: initLatLng.lat || 10.7,
      };
    }
  },
  formatPhone: (phone, countryCode) => {
    const parsedPhone = parsePhoneNumber(`${countryCode}${phone}`);
    if (parsedPhone) {
      return parsedPhone.number;
    }
    return "";
  },
  getMessageTimeStamp: (date) => {
    if (!date) {
      return null;
    }
    const messageDate = moment(date);
    messageDate.locale("vi");
    const timeDif = moment().diff(moment(date), "d");
    const TODAY = moment().startOf("d");
    const YESTERDAY = moment().subtract(1, "days").startOf("day");

    if (messageDate.isSame(TODAY, "day")) {
      return messageDate.format("HH:mm");
    }
    if (messageDate.isSame(YESTERDAY, "day")) {
      return `Hôm qua, ${messageDate.format("HH:mm")}`;
    }
    if (timeDif <= 7) {
      return messageDate.format("dddd HH:mm");
    }
    return messageDate.format("DD/MM/YY HH:mm");
  },
  getTimeNotification: (date, dateFormat) => {
    if (!moment(date, dateFormat).isValid()) {
      return undefined;
    }
    const translatedExpityDateFormat = `YYYY${i18n.t(
      "constants.dateCharacter.year"
    )}MM${i18n.t("constants.dateCharacter.month")}DD${i18n.t(
      "constants.dateCharacter.day"
    )}`;
    const dateStringTranslated = moment(date, dateFormat).format(
      translatedExpityDateFormat
    );
    const today = new Date();
    const notificationTime = new Date(date);
    const diffMs = today - notificationTime; // milliseconds between now & notification time
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if (diffDays < 0) {
      return "Vừa xong";
    }
    if (diffDays === 0) {
      if (diffHrs === 0) {
        if (diffMins === 0) {
          return "Vừa xong";
        }
        return `${diffMins} phút trước`;
      }
      return `${diffHrs} giờ trước`;
    }
    if (diffDays > 7) {
      return dateStringTranslated;
    }
    return `${diffDays} ngày trước`;
  },
  uploadVideo: async (file) => {
    if (file) {
      const payload = new FormData();
      payload.append("file", file.file);

      try {
        const data = await request("/api/upload/upload-video", payload);
        return data[0];
      } catch (e) {
        return null;
      }
    }
    return null;
  },
  uploadImage: async (file) => {
    if (file) {
      const payload = new FormData();
      payload.append("file", file.file);

      try {
        return await request("/api/upload/upload-image", payload);
      } catch (e) {
        return null;
      }
    }
    return null;
  },
  sortStringWithoutSpecialChars: (sourceString) => {
    // eslint-disable-next-line no-useless-escape
    const outString = sourceString.replace(
      // eslint-disable-next-line no-useless-escape
      /[*()+\?;:'",.<>\{\}\[\]\\\/]/gi,
      ""
    );
    return outString;
  },
};

export default Helper;
