import { put, call, takeEvery, takeLeading } from "redux-saga/effects";
import request from "utils/api";
import Helper from "utils/helpers";
import _pick from "lodash/pick";
import _set from "lodash/set";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import { safe } from "providers/GeneralProvider/saga";
import {
  getProductListRequest,
  getProductListSuccess,
  stopLoading,
  setTagRequest,
  setTagSuccess,
  deleteProductRequest,
  deleteProductSuccess,
  getProductTypeRequest,
  getProductTypeSuccess,
  getProductDetailRequest,
  getProductDetailSuccess,
  createProductRequest,
  createProductSuccess,
  updateProductRequest,
  updateProductSuccess,
  recoverProductSuccess,
  recoverProductRequest,
  getInsuranceListRequest,
  getInsuranceListSuccess,
  changeIndexProductTypeRequest,
  changeIndexProductTypeSuccess,
  createTypeProductRequest,
  createTypeProductSuccess,
  deleteTypeProductRequest,
  deleteTypeProductSuccess,
  updateTypeProductRequest,
  updateTypeProductSuccess,
  setProductsPriceRequest,
  setProductsPriceSuccess,
} from "providers/ProductProvider/slice";

function* getProductList(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "sortState",
    "sortGuarantee",
    "sortAmount",
    "sortPrice",
    "sortPromotionPrice",
    "tag",
    "keyword",
    "type",
    "status",
    "line",
    "imei",
    "productId",
  ]);
  if (!params.tag) {
    params.tag = ["HOT", "HIGHLIGHTS", "BUY_NOW", "FONEHOUSE", "EMPTY"];
  } else {
    params.tag = [params.tag];
  }
  if (params.status === "remove") {
    params.status = "remove";
  } else if (params.status === "sold") {
    params.status = "sold";
  } else {
    params.status = "active";
  }

  if (!_isEmpty(params.keyword)) {
    params.keyword =
      Helper.sortStringWithoutSpecialChars(params.keyword) || undefined;
  }
  const { data } = yield call(
    request,
    "api/fonehouse/product-manager/product/get-all",
    params,
    { method: "GET" }
  );
  yield put(getProductListSuccess(data));
}

function* getInsuranceList(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "sortState",
    "sortGuarantee",
    "sortAmount",
    "sortPrice",
    "sortPromotionPrice",
    "tag",
    "keyword",
    "type",
    "status",
  ]);
  params.warrantyExpires = _get(action, "payload.warrantyExpires", 0);
  params.status = "sold";
  if (!_isEmpty(params.keyword)) {
    params.keyword =
      Helper.sortStringWithoutSpecialChars(params.keyword) || undefined;
  }
  const { data } = yield call(
    request,
    "api/fonehouse/product-manager/product/get-all",
    params,
    { method: "GET" }
  );
  yield put(getInsuranceListSuccess(data));
}

function* setTag(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["idProducts", "tag"]);
  yield call(request, "api/fonehouse/product-manager/set-tag-product", params, {
    method: "PUT",
  });
  yield put(setTagSuccess({}, meta));
}

function* deleteProduct(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["idProduct"]);
  const { data } = yield call(
    request,
    "api/fonehouse/product-manager/product",
    params,
    { method: "DELETE" }
  );
  yield put(deleteProductSuccess(data, meta));
  Helper.toastr("Xóa sản phẩm thành công");
}

function* createProduct(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "name",
    "state",
    "imei",
    "softwareVersion",
    "battery",
    "entryDate",
    "accessories",
    "guarantee",
    "address",
    "contact",
    "type",
    "currency",
    "percentagePromotion",
    "promotion",
    "price",
    "line",
    "imageMachine",
    "inputPrice",
    "description",
    "howToBuy",
    "images",
    "ram",
    "amount",
    "os",
    "manufacture",
    "videos",
    "tag",
    "promotionStartDate",
    "promotionExpirationDate",
  ]);
  if (params.entryDate) {
    params.entryDate = new Date(params.entryDate);
  }
  if (params.promotionStartDate) {
    params.promotionStartDate = new Date(params.promotionStartDate);
  } else {
    delete params.promotionStartDate;
  }
  if (params.promotionExpirationDate) {
    params.promotionExpirationDate = new Date(params.promotionExpirationDate);
  } else {
    delete params.promotionExpirationDate;
  }

  Object.keys(params).forEach((param) => {
    if ([undefined, ""].includes(params[param])) {
      params[param] = null;
    }
  });

  if (params.currency === "JPY") {
    _set(params, "inputPriceJPY", params.inputPrice);
    _set(params, "priceJPY", params.price);
    _set(params, "promotionJPY", params.promotion);
    _set(params, "price", 0);
    _set(params, "inputPrice", 0);
    _set(params, "promotion", 0);
  } else {
    _set(params, "priceJPY", 0);
    _set(params, "inputPriceJPY", 0);
    _set(params, "promotionJPY", 0);
  }
  if (!_isEmpty(params.softwareVersion) && _isEmpty(params.os)) {
    Helper.toastr(
      "Thêm loại hệ điều hành",
      "Tạo sản phẩm không thành công",
      "error"
    );
    yield put(stopLoading());
  } else if (!_isEmpty(params.ram) && _isEmpty(action.payload.ramType)) {
    Helper.toastr("Thêm loại Ram", "Tạo sản phẩm không thành công", "error");
    yield put(stopLoading());
  } else {
    const { data } = yield call(
      request,
      "api/fonehouse/product-manager/product",
      params,
      { method: "POST" }
    );
    yield put(createProductSuccess(data, meta));
    Helper.toastr("Tạo sản phẩm thành công");
  }
}

function* updateProduct(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "idProduct",
    "name",
    "state",
    "imei",
    "softwareVersion",
    "battery",
    "entryDate",
    "accessories",
    "guarantee",
    "address",
    "contact",
    "type",
    "currency",
    "percentagePromotion",
    "promotion",
    "price",
    "line",
    "imageMachine",
    "inputPrice",
    "description",
    "howToBuy",
    "images",
    "ram",
    "amount",
    "tag",
    "os",
    "manufacture",
    "videos",
    "promotionStartDate",
    "promotionExpirationDate",
  ]);
  if (params.entryDate) {
    params.entryDate = new Date(params.entryDate);
  }
  if (params.promotionStartDate) {
    params.promotionStartDate = new Date(params.promotionStartDate);
  } else {
    delete params.promotionStartDate;
  }
  if (params.promotionExpirationDate) {
    params.promotionExpirationDate = new Date(params.promotionExpirationDate);
  } else {
    delete params.promotionExpirationDate;
  }

  Object.keys(params).forEach((param) => {
    if ([undefined, ""].includes(params[param])) {
      params[param] = null;
    }
  });

  if (params.currency === "JPY") {
    _set(params, "inputPriceJPY", params.inputPrice);
    _set(params, "priceJPY", params.price);
    _set(params, "promotionJPY", params.promotion);
    _set(params, "price", 0);
    _set(params, "inputPrice", 0);
    _set(params, "promotion", 0);
  } else {
    _set(params, "priceJPY", 0);
    _set(params, "inputPriceJPY", 0);
    _set(params, "promotionJPY", 0);
  }
  if (Number(params.price) === 0 && Number(params.priceJPY) === 0) {
    _set(params, "promotion", 0);
    _set(params, "promotionJPY", 0);
    _set(params, "percentagePromotion", 0);
  }
  if (!_isEmpty(params.softwareVersion) && _isEmpty(params.os)) {
    Helper.toastr(
      "Thêm loại hệ điều hành",
      "Cập nhật sản phẩm không thành công",
      "error"
    );
    yield put(stopLoading());
  } else if (!_isEmpty(params.ram) && _isEmpty(action.payload.ramType)) {
    Helper.toastr(
      "Thêm loại Ram",
      "Cập nhật sản phẩm không thành công",
      "error"
    );
    yield put(stopLoading());
  } else {
    const { data } = yield call(
      request,
      "api/fonehouse/product-manager/product",
      params,
      { method: "PUT" }
    );
    yield put(updateProductSuccess(data, meta));
    Helper.toastr("Cập nhật sản phẩm thành công");
  }
}

function* getProductType() {
  const { data } = yield call(
    request,
    "api/fonehouse/product-manager/product-type",
    {},
    { method: "GET" }
  );
  yield put(getProductTypeSuccess(data));
}

function* getProductDetail(action) {
  const params = _pick(action.payload, ["idProduct"]);
  const { data } = yield call(
    request,
    "api/fonehouse/product-manager/product",
    params,
    { method: "GET" }
  );
  yield put(getProductDetailSuccess(data));
}

function* recoverProduct(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["idProduct"]);
  const { data } = yield call(
    request,
    "api/fonehouse/product-manager/active-product",
    params,
    { method: "PUT" }
  );
  yield put(recoverProductSuccess(data, meta));
  Helper.toastr("Phục hồi sản phẩm thành công");
}

function* changeIndexProductType(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "idProductType",
    "idProductTypeInsert",
  ]);
  const { data } = yield call(
    request,
    "api/fonehouse/product-manager/change-index-product-type",
    params,
    { method: "PUT" }
  );
  yield put(changeIndexProductTypeSuccess(data, meta));
}

function* createTypeProduct(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["name", "machineSeries", "image"]);
  if (params.image) {
    params.image = params.image.url;
  }
  const { data } = yield call(
    request,
    "api/fonehouse/product-manager/product-type",
    params,
    { method: "POST" }
  );
  yield put(createTypeProductSuccess(data, meta));
  Helper.toastr(`Name: ${params.name}`, "Tạo loại sản phẩm thành công");
}

function* updateTypeProduct(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "name",
    "machineSeries",
    "image",
    "idProductType",
  ]);
  const machineSeries = _get(params, "machineSeries", []).map((item) => ({
    id: item.id,
    image: item.image,
    images: item.images,
    name: item.name,
  }));
  const { data } = yield call(
    request,
    "api/fonehouse/product-manager/product-type",
    { ...params, machineSeries },
    { method: "PUT" }
  );
  yield put(updateTypeProductSuccess(data, meta));
  switch (action.payload.type) {
    case "remove-machine-series":
      Helper.toastr(
        `Name: ${action.payload.machineSeriesName}`,
        "Xóa dòng máy thành công"
      );
      break;
    case "create-machine-series":
      Helper.toastr(
        `Name: ${action.payload.machineSeriesName}`,
        "Tạo dòng máy thành công"
      );
      break;
    case "update-machine-series":
      Helper.toastr(
        `Name: ${action.payload.machineSeriesName}`,
        "Cập nhật dòng máy thành công"
      );
      break;
    case "CLOSED":
      Helper.toastr(`Tạo loại ${params.name} thành công`);
      break;
    default:
      break;
  }
}

function* deleteTypeProduct(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["idProductType"]);
  const { data } = yield call(
    request,
    "api/fonehouse/product-manager/product-type",
    params,
    { method: "DELETE" }
  );
  yield put(deleteTypeProductSuccess(data, meta));
  Helper.toastr(`Name: ${action.payload.name}`, "Xóa loại sản phẩm thành công");
}

function* setProductsPrice(action) {
  const result = yield call(
    request,
    "api/fonehouse/product-manager/adjust-product-pricing",
    action.payload,
    { method: "PUT" }
  );
  yield put(setProductsPriceSuccess(result, action.meta));
  if (!_isEmpty(_get(result, "successIds", []))) {
    const successIds = result.successIds.join(", ");
    Helper.toastr(`Cập nhật thành công ${successIds}`);
  }
  if (!_isEmpty(_get(result, "failedIds", []))) {
    const failedIds = result.failedIds.join(", ");
    Helper.toastr(`Không thể cập nhật ${failedIds}`, undefined, "error");
  }
}

function* onError() {
  yield put(stopLoading());
}
export default function* watchAuth() {
  yield takeEvery(getProductListRequest.type, safe(getProductList, onError));
  yield takeEvery(
    getInsuranceListRequest.type,
    safe(getInsuranceList, onError)
  );
  yield takeLeading(setTagRequest.type, safe(setTag, onError));
  yield takeLeading(deleteProductRequest.type, safe(deleteProduct, onError));
  yield takeLeading(createProductRequest.type, safe(createProduct, onError));
  yield takeLeading(updateProductRequest.type, safe(updateProduct, onError));
  yield takeLeading(recoverProductRequest.type, safe(recoverProduct, onError));
  yield takeEvery(getProductTypeRequest.type, safe(getProductType, onError));
  yield takeEvery(
    getProductDetailRequest.type,
    safe(getProductDetail, onError)
  );
  yield takeLeading(
    changeIndexProductTypeRequest.type,
    safe(changeIndexProductType, onError)
  );
  yield takeLeading(
    createTypeProductRequest.type,
    safe(createTypeProduct, onError)
  );
  yield takeLeading(
    updateTypeProductRequest.type,
    safe(updateTypeProduct, onError)
  );
  yield takeLeading(
    deleteTypeProductRequest.type,
    safe(deleteTypeProduct, onError)
  );
  yield takeLeading(
    setProductsPriceRequest.type,
    safe(setProductsPrice, onError)
  );
}
